var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('router-link', {
    staticClass: "btn btn-sm btn-dark",
    attrs: {
      "size": "sm",
      "to": {
        path: '/audio'
      }
    }
  }, [_c('em', {
    staticClass: "fa fa-backward btn-sm btn-dark"
  }), _vm._v(" Back ")]), _c('div', {
    staticClass: "row mt-4 create-new"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "bg-success p-3"
  }, [_c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v("Edit Audio Book")])]), _vm.show ? _c('b-form', {
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-display",
      "label": "Display Name",
      "label-for": "display"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "display",
      "type": "text",
      "placeholder": "Enter Display Name"
    },
    model: {
      value: _vm.form.display_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "display_name", $$v);
      },
      expression: "form.display_name"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Item Status"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "currency",
      "options": _vm.itemStatusOptions,
      "required": ""
    },
    model: {
      value: _vm.form.item_status,
      callback: function ($$v) {
        _vm.$set(_vm.form, "item_status", $$v);
      },
      expression: "form.item_status"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-display",
      "label": "Edition Code",
      "label-for": "display"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "display",
      "type": "text",
      "placeholder": "Enter Edition Code"
    },
    model: {
      value: _vm.form.edition_code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "edition_code", $$v);
      },
      expression: "form.edition_code"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_vm.is_parent != null ? _c('div', [_c('b-form-group', {
    attrs: {
      "id": "input-display",
      "label": "Parent Items",
      "label-for": "display"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "display",
      "readonly": "",
      "type": "text",
      "placeholder": "Enter Edition Code"
    },
    on: {
      "click": _vm.redirectParent
    },
    model: {
      value: _vm.form.parent_items,
      callback: function ($$v) {
        _vm.$set(_vm.form, "parent_items", $$v);
      },
      expression: "form.parent_items"
    }
  })], 1)], 1) : _vm._e()])]), _c('div', {
    staticClass: "row"
  }, [_vm.is_parent == null ? _c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Author"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Enter Author",
      "open-direction": "bottom",
      "options": _vm.authorSearchOptions,
      "searchable": true,
      "loading": _vm.isAuthorSearch,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": true
    },
    on: {
      "search-change": _vm.authorFind,
      "input": _vm.authorSelected
    },
    model: {
      value: _vm.form.author,
      callback: function ($$v) {
        _vm.$set(_vm.form, "author", $$v);
      },
      expression: "form.author"
    }
  })], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Narrator"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Enter Narrator",
      "open-direction": "bottom",
      "options": _vm.narratorSearchOptions,
      "searchable": true,
      "loading": _vm.isNarratorSearch,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": true,
      "required": ""
    },
    on: {
      "search-change": _vm.narratorFind
    },
    model: {
      value: _vm.form.narrator,
      callback: function ($$v) {
        _vm.$set(_vm.form, "narrator", $$v);
      },
      expression: "form.narrator"
    }
  })], 1)], 1)]), _vm.is_parent == null ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-issue",
      "label": "Issue Number (*)",
      "label-for": "issue"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "issue",
      "disabled": _vm.userData.role_id === 5,
      "type": "text",
      "placeholder": "Enter Issue Number",
      "required": ""
    },
    model: {
      value: _vm.form.issue_number,
      callback: function ($$v) {
        _vm.$set(_vm.form, "issue_number", $$v);
      },
      expression: "form.issue_number"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Categories"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Enter Categories",
      "open-direction": "bottom",
      "options": _vm.categoriesSearchOptions,
      "searchable": true,
      "loading": _vm.isCategorySearch,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": true,
      "required": ""
    },
    on: {
      "search-change": _vm.categoriesFind
    },
    model: {
      value: _vm.form.categories,
      callback: function ($$v) {
        _vm.$set(_vm.form, "categories", $$v);
      },
      expression: "form.categories"
    }
  })], 1)], 1)]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Release Date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "datetime-local",
      "required": ""
    },
    model: {
      value: _vm.form.release_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "release_date", $$v);
      },
      expression: "form.release_date"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Release Schedule"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "datetime-local",
      "required": ""
    },
    model: {
      value: _vm.form.release_schedule,
      callback: function ($$v) {
        _vm.$set(_vm.form, "release_schedule", $$v);
      },
      expression: "form.release_schedule"
    }
  })], 1)], 1)]), _vm.is_parent == null ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('label', [_vm._v("Default Languages ")]), _c('multiselect', {
    attrs: {
      "placeholder": "Enter languages",
      "open-direction": "bottom",
      "label": "name",
      "track-by": "name",
      "custom-label": _vm.nameWithISO,
      "options": _vm.languagesSearchOptions,
      "searchable": true,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": true,
      "required": ""
    },
    model: {
      value: _vm.form.languages,
      callback: function ($$v) {
        _vm.$set(_vm.form, "languages", $$v);
      },
      expression: "form.languages"
    }
  })], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('label', [_vm._v("Default Countries ")]), _c('multiselect', {
    attrs: {
      "placeholder": "Enter Countries",
      "open-direction": "bottom",
      "label": "iso",
      "track-by": "iso",
      "custom-label": _vm.nameWithISO,
      "options": _vm.countriesSearchOptions,
      "loading": _vm.isCountriesSearch,
      "searchable": true,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": true
    },
    model: {
      value: _vm.form.countries,
      callback: function ($$v) {
        _vm.$set(_vm.form, "countries", $$v);
      },
      expression: "form.countries"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Description"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "description",
      "placeholder": "Enter Description Here...",
      "rows": "3",
      "max-rows": "6"
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.form.is_active,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_active", $$v);
      },
      expression: "form.is_active"
    }
  }, [_vm._v("Active ")])], 1)], 1)])]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v("Edit Audio Books")])], 1), _c('br'), _c('br'), _c('br')]) : _vm._e()], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }