<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <router-link class="btn btn-sm btn-dark  "  size="sm"  :to="{ path: '/audio'}">
      <em class="fa fa-backward btn-sm btn-dark "></em> Back
    </router-link>
    <div class="row mt-4 create-new">
      <div class="col-md-12">
        <div class="card">
          <div class="bg-success p-3">
            <h5 class="card-title mb-0 font-weight-bold">Edit Audio Book</h5>
          </div>
          <b-form @submit="onSubmit" v-if="show">
            <div class="card-body">
              <div class="row">
              <div class="col-md-6">
                <b-form-group id="input-display" label="Display Name" label-for="display">
                  <b-form-input id="display" v-model="form.display_name" type="text" placeholder="Enter Display Name">
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Item Status">
                  <b-form-select id="currency" v-model="form.item_status" :options="itemStatusOptions" required>
                  </b-form-select>
                </b-form-group>
              </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                  <b-form-group id="input-display" label="Edition Code" label-for="display">
                    <b-form-input id="display" v-model="form.edition_code" type="text" placeholder="Enter Edition Code">
                    </b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                <div v-if="is_parent != null">
                  <b-form-group id="input-display" label="Parent Items" label-for="display">
                    <b-form-input id="display" v-model="form.parent_items" @click="redirectParent" readonly type="text" placeholder="Enter Edition Code">
                    </b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-md-6" v-if="is_parent == null">
                <b-form-group label="Author" >
                  <multiselect v-model="form.author" label="name" track-by="name" placeholder="Enter Author"
                  open-direction="bottom" :options="authorSearchOptions" :searchable="true" :loading="isAuthorSearch"
                  :close-on-select="true" :options-limit="10" :multiple="true" @search-change="authorFind"
                  @input="authorSelected">
                </multiselect>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Narrator" >
                    <multiselect v-model="form.narrator" label="name" track-by="name" placeholder="Enter Narrator"
                    open-direction="bottom" :options="narratorSearchOptions" :searchable="true" :loading="isNarratorSearch"
                    :close-on-select="true" :options-limit="10" :multiple="true" @search-change="narratorFind" required
                    >
                  </multiselect>
                </b-form-group>
              </div>
            </div>
            <div class="row" v-if="is_parent == null">
              <div class="col-md-6">
                <b-form-group id="input-issue" label="Issue Number (*)" label-for="issue">
                  <b-form-input id="issue" :disabled="userData.role_id === 5"  v-model="form.issue_number" type="text" placeholder="Enter Issue Number"
                    required>
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Categories" >
                    <multiselect v-model="form.categories" label="name" track-by="name" placeholder="Enter Categories"
                    open-direction="bottom" :options="categoriesSearchOptions" :searchable="true" :loading="isCategorySearch"
                    :close-on-select="true" :options-limit="10" :multiple="true" @search-change="categoriesFind" required
                    >
                  </multiselect>
                </b-form-group>
              </div>
            </div>


            <div class="row">
              <div class="col-md-6 ">
                <b-form-group label="Release Date">
                  <b-form-input type="datetime-local" v-model="form.release_date" required></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Release Schedule">
                  <b-form-input type="datetime-local" v-model="form.release_schedule" required></b-form-input>
                </b-form-group>
              </div>
            </div>  
            <div class="row" v-if="is_parent == null">
              <div class="col-md-6">
                <label>Default Languages </label>
                <multiselect
                  placeholder="Enter languages"
                  open-direction="bottom"
                  v-model="form.languages"
                  label="name"
                  track-by="name"
                  :custom-label="nameWithISO"
                  :options="languagesSearchOptions"
                  :searchable="true"
                  :close-on-select="true"
                  :options-limit="10"
                  :multiple="true"
                  
                  required
                >
                </multiselect>
              </div>
              <div class="col-md-6">
                <label>Default Countries </label>
                <multiselect
                  placeholder="Enter Countries"
                  open-direction="bottom"
                  v-model="form.countries"
                  label="iso"
                  track-by="iso"
                  :custom-label="nameWithISO"
                  :options="countriesSearchOptions"
                  :loading="isCountriesSearch"
                  :searchable="true"
                  :close-on-select="true"
                  :options-limit="10"
                  :multiple="true">
                </multiselect>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                  <b-form-group label='Description'>
                    <b-form-textarea id="description" v-model="form.description" placeholder="Enter Description Here..." rows="3" max-rows="6">
                    </b-form-textarea>
                  </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-form-group>
                  <b-form-checkbox v-model="form.is_active" :value="true" :unchecked-value="false">Active
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="col-md-12">
              <b-button variant='primary' type='submit'>Edit Audio Books</b-button>
            </div>
            <br/><br/><br/>


          </b-form>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import constant from "../../../store/constant";

const ITEM_STATUS = Object.entries(constant.ITEM_STATUS).map(item => { return { text: item[0], value: item[1] } })

export default {
  name: "editAudioBook",
  data() {
    return {
      userData : JSON.parse(localStorage.getItem('session_auth')),
      form: {
        display_name: "",
        edition_code:"",
        item_status: "",
        release_date: "",
        release_schedule: "",
        is_active: false,
        description: "",
        parent_items:null,
        author: [],
        narrator:[],
        countries:[],
        categories:[],
        languages:[],
        issue_number:null,
      },
      file_content: null,
      file_content_view: null,
      itemStatusOptions: ITEM_STATUS,
      show: true,
      item_id: null,
      is_parent: null,
      authorSearchOptions: [],
      narratorSearchOptions:[],
      categoriesSearchOptions:[],
      countriesSearchOptions: [],
      languagesSearchOptions: [],
      isAuthorSearch: false,
      isNarratorSearch: false,
      isCountriesSearch: false,
      isCategorySearch:false,
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.audioBooks.isError,
      isLoading: (state) => state.audioBooks.isLoading,
      errorMessage: (state) => state.audioBooks.errorMessage,
      successMessage: (state) => state.audioBooks.successMessage,
      item: (state) => state.audioBooks.item,
      refresh: (state) => state.audioBooks.refresh,
      narrators: (state) =>state.audioBooks.narrator,
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function () {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
      this.$router.push({
        path: `/audio`,
      })

    },
    item: function () {
      if (!Object.keys(this.item).length) return;
      this.setEditAudioBook();
    },
    refresh: function () {
      if (!this.refresh) return;
      this.fetchAudioBookById({ id: this.item_id });
    },
    file_content: function () {
      if (!this.file_content) {
        this.file_content_view = '';
      } else {
        this.file_content_view = URL.createObjectURL(this.file_content);
      }
    }
  },
  created() {
    let id = this.$route.params.id;
    this.item_id = id;
    this.fetchAudioBookById({ id });
    this.setCountriesOptions();
    this.languagesSearchOptions = Object.entries(constant.LANGUAGE_CHOICES).map((items) => { 
      return {
        iso: items[0].toUpperCase(),
        name: items[0]
      } 
    });
  },
  methods: {
    ...mapActions("narrator", ["fetch"]),
    ...mapActions("author", ["searchAuthor"]),
    ...mapActions('items', ['searchCategories',  'getCountries']),
    ...mapActions("audioBooks", ["updateAudioBooks",  "fetchAudioBookById","searchParentsById"]),
    onSubmit(event) {
      event.preventDefault();
      const payload = {
        item_id: this.item_id,
        name: this.form.display_name,
        is_active: this.form.is_active,
        release_date: this.form.release_date,
        release_schedule: this.form.release_schedule,
        item_status: this.form.item_status,
        description: this.form.description,
        edition_code: this.form.edition_code,
        issue_number: this.form.issue_number,
        narrator_id: this.form.narrator.map((item) => item.id),
        author_id: this.form.author.map((item) => item.id),
        categories_id: this.form.categories.map((item) => item.id),
        languages: this.form.languages.map(item => item.iso.toLowerCase()),
        countries: this.form.countries.map(item=>item.iso.toLowerCase()),
        
      };
      this.updateAudioBooks(payload);
    },
    async authorSelected(data) {
     await this.setIssueNumber(data);
    },
    async setIssueNumber(data) {
      this.form.issue_number = null;
      if (data) {
        const issue_number = data[0].name;
        this.form.issue_number = issue_number;
      }
      
    },
    setCountriesOptions() {
      this.isCountriesSearch = true;
      this.getCountries()
      .then((response) => {
        this.countriesSearchOptions = response.data.data.rows;
        this.isCountriesSearch = false;
      })
      .catch(() => {
        this.isCountriesSearch = false;
      });
    },nameWithISO({ name, iso }) {
      return `${iso} - ${name}`
    },messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    authorFind(query) {
      if (!query) return;

      this.isAuthorSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          limit: 10,
        };
        this.searchAuthor(payload).then((response) => {
          this.authorSearchOptions = response.data.data.rows;
          this.isAuthorSearch = false;
        }).catch(() => {
          this.isAuthorSearch = false;
        });
      }, 600);
    },
    narratorFind(query) {
      if (!query) return;
      this.isNarratorSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          query,
          limit: 10,
        };
        this.fetch(payload).then((response) => {
          this.narratorSearchOptions = response.data.data.rows;
          this.isNarratorSearch = false;
        }).catch(() => {
          this.isNarratorSearch = false;
        });
      }, 600);
    },
    categoriesFind(query) {
      if (!query) return;
      this.isCategoriesSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchCategories(query)
        .then((response) => {
          this.categoriesSearchOptions = response.data.data.rows;
          this.isCategoriesSearch = false;
        }).catch(() => {
          this.isCategoriesSearch = false;
        });
      }, 600);
    },
   async setEditAudioBook() {
      const data = this.item;
      this.form.display_name = data.name;
      this.form.release_date = moment(data.release_date).format('YYYY-MM-DD hh:mm');
      this.form.release_schedule = moment(data.release_schedule).format('YYYY-MM-DD hh:mm');
      this.form.item_status = data.item_status;
      this.form.is_active = data.is_active;
      this.form.description = data.description;
      this.form.edition_code = data.edition_code;
      this.form.issue_number = data.issue_number;
      this.is_parent = data.parent_item_id;
      if(data.parent_item_id!=null){
        this.is_parent=data.parent_item_id;
        let parents =  await this.searchParentsById({id:data.parent_item_id});
        this.form.parent_items = parents.data.data.rows[0].name;
      }
      if(data.languages){
        this.form.languages = this.languagesSearchOptions
        .filter(
          items => [...data.languages].includes(items.iso.toLowerCase())
      );
      }
      if(data.countries){
        this.form.countries = this.countriesSearchOptions
        .filter(
          items => [...data.countries].includes(items.iso.toLowerCase())
        );
      }
      if(this.narrators) {
        this.form.narrator = this.narrators;
      }
      if(data.authors) {
        this.form.author = data.authors;
      }
      if(data.categories) {
        this.form.categories = data.categories;
      }
    },
    redirectParent(){
      this.$router.push({
        path: `/items/detail/${this.is_parent}`,
      });
    },
  },
  filters: {
    convertSize(value) {
      const size = parseFloat(value);
      return (size / 1000 ** 2).toFixed(1)
    },
    msToMinutes(value) {
      return value / 6000;
    }
  }
};
</script>

<style lang="css">
.create-new {
  font-family: "Avenir Next", "effra", Helvetica, Arial, sans-serif;
}
</style>
